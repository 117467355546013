var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('div',{class:[
          _vm.className,
          _vm.warningClass,
          'font-weight-regular',
          'ma-0 pa-0',
          'text--lighten-1',
          'text-center'
        ]},[(_vm.elapsed)?_c('span',{domProps:{"innerHTML":_vm._f("durationSemicolons")(_vm.elapsed)}}):_c('span',[_vm._v("00:00")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }