var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.vertical,{tag:"component",scopedSlots:_vm._u([{key:"stepper",fn:function(){return [_c('vue-scroll',{ref:"vs"},[_c('v-container',{staticStyle:{"max-width":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-0",staticStyle:{"flex-wrap":"nowrap","width":"100%","display":"flex"},style:({ 'max-width': _vm.getStepsBlockHeight() }),attrs:{"fluid":""}},_vm._l((_vm.steps.filter(function (step) { return step.title; })),function(step,idx){return _c('v-col',{key:step.state,staticClass:"caption black--text ma-0 pa-0",class:[
              _vm.currentStep.state === step.state ?
              'text--darken-5' :
              'grey--text'
            ],style:({
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
              whiteSpace: 'nowrap'
            }),attrs:{"id":step.state}},[_vm._v(" "+_vm._s(_vm.$t(step.title))+" "),(idx < _vm.steps.length - 2)?_c('div',{staticClass:"arrow-wrapper mx-8",class:{ 'xs': _vm.isXs }},[_c('div',{staticClass:"arrow"})]):_vm._e()])}),1)],1)],1)]},proxy:true},{key:"round-header",fn:function(){return [_c('v-container',{staticClass:"ma-0 pa-0 mb-2"},[(_vm.liveMode)?_c('v-row',{staticClass:"pt-0 pb-0"},[_c('v-col',[_c('round-header',{attrs:{"upperMsg":_vm.$t('Total time'),"className":"text-h5 text-center"}})],1),(_vm.event.type === 'chase' && _vm.currentStep.state === 'upptag')?_c('v-col',[_c('round-header',{attrs:{"upperMsg":_vm.currentStep.timer || _vm.currentStep.title,"className":"text-h5 text-center"}})],1):_vm._e()],1):_vm._e(),(_vm.liveMode)?_c('v-row',{class:[
        'pt-0',
        _vm.currentStep.state === 'upptag' ? 'pb-0' : 'pb-5'
      ]},[(_vm.event.type === 'chase')?_c('v-col',[_c('total-time',{attrs:{"elapsed":_vm.globalElapsed,"paused":false,"warning":25200000,"className":"text-h5"}})],1):_c('v-col',[_c('total-time',{attrs:{"elapsed":_vm.elapsed,"paused":_vm.paused,"className":"text-h5"}})],1),(_vm.currentStep.state === 'upptag')?_c('v-col',[_c('total-time',{attrs:{"elapsed":_vm.currentElapsed,"paused":_vm.paused,"className":"text-h5"}})],1):_vm._e()],1):_vm._e(),(_vm.currentStep.state === 'upptag' && _vm.liveMode)?_c('v-row',{staticClass:"pt-0 pb-5"},[_c('v-col',[_c('round-header',{attrs:{"upperMsg":"Remaining test time","className":"text-right"}})],1),_c('v-col',[_c('div',{class:[
                _vm.warnTestTime ? 'red--text' : 'grey--text',
                'font-weight-regular',
                'ma-0 pa-0',
                'text--lighten-1' ],domProps:{"innerHTML":_vm._f("durationSemicolons")(_vm.remainingTestTime(3))}})])],1):_vm._e(),(_vm.showStats && _vm.event.type === 'chase')?_c('v-row',[_c('v-col',[_c('b',[_vm._v("Detta släpp")]),_c('br'),_c('span',[_vm._v("Total tid:")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._f("durationNoSemicolonsZero")(_vm.currentRoundElapsed)}}),_c('br'),_c('span',[_vm._v("Totalt tappt:")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._f("durationNoSemicolonsZero")(_vm.getDurationByType('tappt'))}}),_c('br'),_c('span',[_vm._v("Återstående tid:")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._f("durationNoSemicolonsZero")(_vm.remainingTestTime(3))}}),_c('br'),_c('br'),_c('b',[_vm._v("Totalt för provtillfälle")]),_c('br'),_c('span',[_vm._v("Total tid:")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._f("durationNoSemicolonsZero")(_vm.globalElapsed)}}),_c('br'),_c('span',[_vm._v("Återstående tid:")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._f("durationNoSemicolonsZero")(_vm.globalRemainingTestTime(7))}}),_c('br'),_c('span',[_vm._v("Total losstid:")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._f("durationNoSemicolonsZero")(_vm.globalElapsedBySteps('start', 'stop'))}}),_c('br'),_c('span',[_vm._v("Total drevtid:")]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._f("durationNoSemicolonsZero")(_vm.globalElapsedBySteps('upptag', 'inkall'))}}),_c('br')]),_c('v-col',[_c('v-btn',{staticClass:"float-right",on:{"click":_vm.toggleStats}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)],1):_vm._e(),(!_vm.showStats)?_c('v-row',[_c('v-col',[(_vm.event.type === 'chase' && _vm.liveMode)?_c('v-btn',{staticClass:"float-right ml-2",on:{"click":_vm.toggleStats}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),_c('v-btn',{class:[
              'float-right',
              _vm.liveMode ? 'green white--text' : 'red white--text' ],on:{"click":_vm.toggleLiveMode}},[(_vm.liveMode)?_c('v-icon',[_vm._v("mdi-timer")]):_c('v-icon',[_vm._v("mdi-timer-cog")])],1)],1)],1):_vm._e(),_c('v-row',[_c('round-action-btns',{attrs:{"buttons":_vm.buttons.filter(function (b) { return b && (!_vm.closed || b.active); }),"additional":_vm.additionalButtons.filter(function (b) { return b && (!_vm.closed || b.active); })}})],1)],1)]},proxy:true},{key:"round-timetable",fn:function(){return [(_vm.round.steps.length > 0)?_c('round-timetable',{staticStyle:{"width":"100%"},attrs:{"round":_vm.round,"steps":_vm.additionalSteps.concat( _vm.steps ),"closed":_vm.closed,"editStep":_vm.editStep,"currentStep":_vm.currentStep}}):_vm._e()]},proxy:true},{key:"round-bottom",fn:function(){return [_c('v-col',[(_vm.running)?_c('v-btn',{staticClass:"white--text",attrs:{"block":"","color":"red","elevation":"0","xLarge":""},on:{"click":_vm.abortRound}},[_vm._v(" "+_vm._s(_vm.$t('Abort round'))+" ")]):_c('v-btn',{attrs:{"block":"","color":"primary","elevation":"0","xLarge":""},on:{"click":_vm.goBack}},[_c('v-icon',[_vm._v("mdi-undo-variant")]),_vm._v(" "+_vm._s(_vm.$t('Back'))+" ")],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }