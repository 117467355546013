<template>
  <div>
    <layout-single
      v-if="ready"
      v-on="listeners"
      :modals="modals"
    >
      <template #content>
        <round-timer
          :additionalSteps="additionalSteps"
          :currentElapsed="currentElapsed"
          :currentStep="currentStep"
          :elapsed="elapsed"
          :elapsedBySteps="elapsedBySteps"
          :globalElapsed="globalElapsed"
          :globalElapsedBySteps="globalElapsedBySteps"
          :globalRemainingTestTime="globalRemainingTestTime"
          :remainingTestTime="remainingTestTime"
          :round="round"
          :roundElapsed="roundElapsed"
          :steps="steps"
          :timerState="timerState"
          :timestamp="timestamp"
          @pushState="pushState"
          v-on="listeners"
        />
      </template>
    </layout-single>
  </div>
</template>

<script>
import LayoutSingle from '@/components/layout-single'
import RoundTimer from '@/components/registration/timer/round-timer'

import mixin from '@/components/registration/round/mixin'

export default {
  name: 'Round',

  components: {
    RoundTimer,
    LayoutSingle
  },

  mixins: [
    mixin
  ],

  mounted () {
    if (!this.event) {
      this.$router.push('/event').catch(() => {})
    } else if (!this.registration) {
      this.$router.push(`/event/${this.$route.params.eventId}`).catch(() => {})
    } else if (!this.round) {
      this.$router.push(`/event/${
        this.$route.params.eventId
      }/registration/${
        this.$route.params.registrationId
      }`).catch(() => {})
    }

    let title = `${this.$t('Round')} ${this.roundId + 1}`;
    if (this.event.type === 'bloodtracking') {
      title = this.$t('Bloodtracking');
    }

    this.$store.commit('setNavigation', {
      show: true,
      to: `/event/${
        this.$route.params.eventId
      }/registration/${
        this.$route.params.registrationId
      }`,
      title,
    })
  }
}
</script>
