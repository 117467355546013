<template>
  <span>
    <span v-html="prefix" />
    <span :inner-html.prop="currentElapsed | durationNoSemicolons" />
    <span v-html="suffix" />
  </span>
</template>

<script>
export default {
  name: 'TimeElapsed',

  props: {
    elapsed: {
      type: Function,
      required: true
    },

    prefix: {
      type: String,
      default: ' '
    },

    suffix: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      currentElapsed: null,
      interval: null
    }
  },

  mounted () {
    this.interval = setInterval(() => {
      try {
        this.currentElapsed = this.elapsed()
      } catch (err) {
        clearInterval(this.interval)
      }
    }, 500)
  }
}
</script>
