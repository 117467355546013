<template>
  <component :is="vertical">
    <template #stepper>
      <vue-scroll ref="vs">
        <v-container fluid style="max-width: 100%;">
          <v-row
            fluid
            class="pt-0"
            style="flex-wrap: nowrap; width: 100%; display: flex;"
            :style="{ 'max-width': getStepsBlockHeight() }"
          >
            <v-col
              v-for="(step, idx) in steps.filter(step => step.title)"
              :id="step.state"
              :key="step.state"
              :style="{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
                whiteSpace: 'nowrap'
              }"
              class="caption black--text ma-0 pa-0"
              :class="[
                currentStep.state === step.state ?
                'text--darken-5' :
                'grey--text'
              ]"
            >

                {{ $t(step.title) }}

              <div
                v-if="idx < steps.length - 2"
                class="arrow-wrapper mx-8" :class="{ 'xs': isXs }"
              >
                <div class="arrow"></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </vue-scroll>
    </template>

    <template #round-header>
      <v-container class="ma-0 pa-0 mb-2">
        <v-row class="pt-0 pb-0" v-if="liveMode">
          <v-col>
            <round-header
              :upperMsg="$t('Total time')"
              className="text-h5 text-center"
            />
          </v-col>
          <v-col v-if="event.type === 'chase' && currentStep.state === 'upptag'">
            <round-header
              :upperMsg="currentStep.timer || currentStep.title"
              className="text-h5 text-center"
            />
          </v-col>
        </v-row>
        <v-row :class="[
          'pt-0',
          currentStep.state === 'upptag' ? 'pb-0' : 'pb-5'
        ]" v-if="liveMode">
          <v-col v-if="event.type === 'chase'">
            <total-time
              :elapsed="globalElapsed"
              :paused="false"
              :warning="25200000"
              className="text-h5"
            />
          </v-col>
          <v-col v-else>
            <total-time
              :elapsed="elapsed"
              :paused="paused"
              className="text-h5"
            />
          </v-col>
          <v-col v-if="currentStep.state === 'upptag'">
            <total-time
              :elapsed="currentElapsed"
              :paused="paused"
              className="text-h5"
            />
          </v-col>
        </v-row>
        <v-row v-if="currentStep.state === 'upptag' && liveMode" class="pt-0 pb-5">
          <v-col>
            <round-header
              upperMsg="Remaining test time"
              className="text-right"
            />
          </v-col>
          <v-col>
            <div
                :class="[
                  warnTestTime ? 'red--text' : 'grey--text',
                  'font-weight-regular',
                  'ma-0 pa-0',
                  'text--lighten-1',
                ]"
                :inner-html.prop="remainingTestTime(3) | durationSemicolons"
              />
          </v-col>
        </v-row>

        <v-row v-if="showStats && event.type === 'chase'">
          <v-col>
            <b>Detta släpp</b><br>

            <span>Total tid:</span>&nbsp;
            <span :inner-html.prop="currentRoundElapsed | durationNoSemicolonsZero" /><br>

            <span>Totalt tappt:</span>&nbsp;
            <span :inner-html.prop="getDurationByType('tappt') | durationNoSemicolonsZero" /></span><br>

            <span>Återstående tid:</span>&nbsp;
            <span :inner-html.prop="remainingTestTime(3) | durationNoSemicolonsZero" /><br>

            <br>
            <b>Totalt för provtillfälle</b><br>

            <span>Total tid:</span>&nbsp;
            <span :inner-html.prop="globalElapsed | durationNoSemicolonsZero" /><br>

            <span>Återstående tid:</span>&nbsp;
            <span :inner-html.prop="globalRemainingTestTime(7) | durationNoSemicolonsZero" /><br>

            <span>Total losstid:</span>&nbsp;
            <span :inner-html.prop="globalElapsedBySteps('start', 'stop') | durationNoSemicolonsZero" /><br>

            <span>Total drevtid:</span>&nbsp;
            <span :inner-html.prop="globalElapsedBySteps('upptag', 'inkall') | durationNoSemicolonsZero" /><br>

          </v-col>
          <v-col >
            <v-btn
              class="float-right"
              @click="toggleStats"
            ><v-icon>mdi-chevron-up</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row v-if="!showStats">
          <v-col>
            <v-btn
              v-if="event.type === 'chase' && liveMode"
              class="float-right ml-2"
              @click="toggleStats"
            ><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-btn
              :class="[
                'float-right',
                liveMode ? 'green white--text' : 'red white--text',
              ]"
              @click="toggleLiveMode"
            >
              <v-icon v-if="liveMode">mdi-timer</v-icon>
              <v-icon v-else>mdi-timer-cog</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <round-action-btns
            :buttons="buttons.filter(b => b && (!closed || b.active))"
            :additional="additionalButtons.filter(b => b && (!closed || b.active))"
          />
        </v-row>
      </v-container>
    </template>

    <template #round-timetable>
      <round-timetable
        v-if="round.steps.length > 0"
        style="width: 100%;"
        :round="round"
        :steps="[ ...additionalSteps, ...steps ]"
        :closed="closed"
        :editStep="editStep"
        :currentStep="currentStep"
      />
    </template>

    <template #round-bottom>
        <v-col>
          <v-btn
            v-if="running"
            block
            class="white--text"
            color="red"
            elevation="0"
            xLarge
            @click="abortRound"
          >
            {{ $t('Abort round') }}
          </v-btn>
          <v-btn
            block
            color="primary"
            elevation="0"
            xLarge
            v-else
            @click="goBack"
          >
            <v-icon>mdi-undo-variant</v-icon> {{ $t('Back') }}
          </v-btn>
        </v-col>
    </template>
  </component>
</template>

<script>
import vueScroll from 'vuescroll'
import RoundShowVertical from '@/views/round/layout'
import Duration from '@/plugins/duration'

import RoundTimetable from '@/components/registration/wrapper/table'
import RoundHeader from '@/components/registration/wrapper/header'
import RoundActionBtns from '@/components/registration/wrapper/action-btns'

import TotalTime from '@/components/registration/timer/total-time'

export default {
  name: 'RoundTimer',

  components: {
    vueScroll,
    RoundActionBtns,
    RoundHeader,
    TotalTime,
    RoundTimetable,
    RoundShowVertical,
  },

  props: {
    timerState: {
      type: String,
      required: true
    },

    elapsed: {
      type: Number,
      required: true
    },

    timestamp: {
      type: Number,
      required: true
    },

    steps: {
      type: Array,
      default: () => []
    },

    round: {
      type: Object,
      default: () => {}
    },

    additionalSteps: {
      type: Array,
      default: () => []
    },

    currentStep: {
      type: Object,
      required: true
    },

    currentElapsed: {
      type: Number,
      required: true
    },

    remainingTestTime: {
      type: Function,
      required: true
    },

    roundElapsed: {
      type: Number,
      required: true
    },

    globalElapsed: {
      type: Number,
      required: true
    },

    globalElapsedBySteps: {
      type: Function,
      required: true
    },

    globalRemainingTestTime: {
      type: Function,
      required: true
    },

    elapsedBySteps: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    vertical: 'RoundShowVertical',
    history: [],
    showAddEdit: false,
    warnTestTime: false,
    modalShouldUpdate: false,
    toggleState: {},
    updatedActionId: null,
    onCloseModal: () => {},
    addTimestamp: Date.now(),
    showAbortConfirmation: false,
    onCloseConfirmationModal: () => {},
    loading: false,
    showStats: false,
    liveMode: true,
  }),

  computed: {
    isXs () {
      return this.$vuetify.breakpoint.name === 'xs'
    },

    paused () {
      return !this.running
    },

    closed () {
      return this.timerState === 'timer.closed'
    },

    running () {
      return this.timerState === 'timer.running'
    },

    event () {
      return this.$store.state.rx.events.find(
        (e) => e.id === this.$route.params.eventId
      )
    },

    currentRoundElapsed () {
      return this.closed ? this.elapsedBySteps('start', 'stop') : this.roundElapsed
    },

    additionalButtons () {
      return [
        //  {
          //  width: 6,
          //  color: 'grey lighten-2',
          //  text: 'grey--text text--darken-2',
          //  message: this.$t('Back'),
          //  icon: 'mdi-undo-variant',
          //  callback: this.goBack.bind(this),
        //  },
        //  {
          //  width: 6,
          //  color: this.paused ?  'blue' : 'grey lighten-2',
          //  text: this.paused ? 'white--text' : 'grey--text text--darken-2',
          //  message: () => {
            //  return this.$t(this.paused ? 'Resume' : 'Pause')
          //  },
          //  icon: this.paused ? 'mdi-play' : 'mdi-pause',
          //  disabled: this.currentStep.state === 'init' || !this.liveMode,
          //  callback: () => {
            //  this.$emit('pushState', this.paused ? 'restart' : 'pause')
          //  }
        //  },
        ...this.additionalSteps.filter((s) => (s.display !== false)).map((s) => {
          return {
            width: 6,
            outline: () => {
              if (s.duration !== true) {
                return false
              }

              const step = this.findLastStepByType(s.state)
              let running = step ? true : false

              if (step) {
                if (step.results) {
                  const result = step.results.find(
                    (r) => r.type === `${s.state}.duration`
                  )
                  running = !result
                }
              }

              return !running
            },
            message: () => {
              const title = s.button ?? s.title
              if (s.duration !== true) {
                if (!s.scope) {
                  return title
                }

                const result = this.round.results.find((r) => r.type === s.scope)
                if (!result) {
                  return title
                }

                return `${this.$t(title)} (${result.value})`
              }

              const step = this.findLastStepByType(s.state)
              let running = step && step.results ? false : true

              const reversed = s.reversed ?? false;
              const stop = reversed ? 'Start' : 'Stop'
              const start = reversed ? 'Stop' : 'Start'

              if (running && step) {
                const dur = Duration.duration(
                  Math.max(this.elapsed - step.elapsed, 0),
                  true,
                  true,
                );

                return `${this.$t(`${stop} ${title}`)} (${dur})`
              }

              return `${this.$t(this.liveMode ? `${start} ${title}` : `New ${title}`)}`
            },
            color: () => {
              if (s.duration !== true) {
                if (!s.scope) {
                  return 'green'
                }

                const result = this.round.results.find((r) => r.type === s.scope)
                if (!result) {
                  return 'blue'
                }

                return 'green'
              }

              return 'green'
            },
            text: 'white--text',
            active: s.active,
            disabled: s.when
              ? !s.when.includes(this.currentStep.state)
              : !(s.active || this.currentStep.state !== 'init'),
            callback: () => {
              if (s.trigger) {
                this.triggerEvent(s.trigger, s.scope)
                return
              }

              if (!this.liveMode) {
                this.$emit('addModalAdditional', s.state)
                return
              }

              let { state } = s

              if (s.toggle === true) {
                const current = this.toggleState[s.state]
                this.toggleState[s.state] = !current
                state = `${s.state}${ current ? '.stop' : '' }`
              }

              this.$emit('pushState', state)
            }
          }
        })
      ]
    },

    buttons () {
      return [
        ...this.steps.map((s, i) => {
          const prev = this.steps[i - 1]

          if (!prev) {
            return {
              prop: false
            }
          }

          let message = this.$t(s.title).toLowerCase();

          if (!s.title.match(/start|stop/i)) {
            message = `${this.$t(this.liveMode ? 'Start' : 'New')} ${message}`;
          } else if (!this.liveMode) {
            if (s.title === 'Stop track') {
              message = this.$t('New lease').toLowerCase();
            } else if (s.title === 'Stop test') {
              message = this.$t(s.title).toLowerCase();
            } else {
              message = this.$t(s.title.replace(/start|stop/i, 'New')).toLowerCase();
            }
          }

          return {
            width: 12,
            color: 'blue',
            text: 'white--text',
            message,
            active: s.active,
            prop: s.title && this.currentStep.state === prev.state,
            callback: () => {
              if (!this.liveMode) {
                this.$emit('addModal')
                return;
              }

              this.$emit('pushState', s.state)
            }
          }
        }).filter((button) => button.prop === true)
      ]
    }
  },

  watch: {
    timerstate() {
      this.$refs.vs.scrollIntoView(`#${this.currentStep.state}`)
    },

    currentStep () {
      this.$refs.vs.scrollIntoView(`#${this.currentStep.state}`)
    }
  },

  mounted () {
    this.$refs.vs.scrollIntoView(`#${this.currentStep.state}`)
  },

  methods: {
    toggleLiveMode() {
      this.liveMode = !this.liveMode;
    },
    toggleStats() {
      this.showStats = !this.showStats;
    },

    findLastStepByType(type) {
      return _.last(this.round.steps.filter((s) => s.state === type))
    },

    getDurationByType(type) {
      return _.sum(this.round.steps.filter((s) => s.state === type).map((s) => {
        if (s.results) {
          const result = s.results.find(r => r.type === `${type}.duration`)
          return result ? result.value : 0
        }

        return this.elapsed - s.elapsed
      }))
    },

    editStep (id) {
      this.$emit('editModal', id)
    },

    abortRound () {
      this.$emit('pushState', 'abort')
    },

    goBack () {
      this.$emit('goBack')
    },

    triggerEvent (trigger, scope) {
      this.$emit(trigger, scope)
    },

    getStepsBlockHeight () {
      const width = window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      if (width <= 575) {
        return `${width - 24}px`
      }

      return 'auto'
    }
  }
}
</script>

<style lang="scss">
  .seconds {
    opacity: 0.3;
  }
  .plus {
    opacity: 0.3;
  }
  .arrow-wrapper {
    align-self: stretch;
    justify-self: stretch;
    width: 100%;
    height: 100%;
    position: relative;

    &.xs {
      transform: scale(0.5);
    }
    &:not(.xs) {
      transform: scale(0.7);
    }

    .arrow {
      background: #616161;
      position: absolute;
      width: 60%;
      left: 20%;
      top: 50%;
      height: 1px;

      &:after {
        content: '';
        position: absolute;
        left: 100%;
        top: 50%;
        width: 10px;
        height: 10px;
        transform-origin: center;
        transform: translate(-100%, -50%) rotate(45deg);
        border-right: 1px solid lighten(#616161, 30%);
        border-top: 1px solid lighten(#616161, 30%);
      }
    }
  }
</style>
