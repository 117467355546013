<template>
  <div style="display: flex; flex-direction: column;">
    <v-simple-table v-if="round.steps" class="transparent mb-2 pb-2">
      <template #default>
        <tbody>
          <tr
            v-for="(step, id) in round.steps"
            :key="`tr-${id}-${evaluated(step)}-${comment(step)}`"
            :class="[ muted(step.state) ? 'grey--text' : '']"
          >
            <td>
              <span
                :class="[
                  'md',
                  'lg',
                  'xl',
                  'xxl'
                ].includes($vuetify.breakpoint.name) ? 'body-1' : ''"
              >{{ $date(new Date(step.timestamp)).format('H:mm') }}</span>
            </td>

            <td style="width:100%;">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  {{ $t(title(step.state)) }}

                  <v-icon
                    v-if="evaluated(step) === 'valid'"
                    color="green"
                  >mdi-check</v-icon>

                  <v-icon
                    v-if="comment(step)"
                    v-bind="attrs"
                    v-on="on"
                  >mdi-comment</v-icon>

                  <span
                    class="grey--text text--darken-1"
                    :v-if="duration(step)"
                    :inner-html.prop="duration(step) | durationNoSemicolons"
                  />
                </template>

                <span>{{ comment(step) }}</span>
              </v-tooltip>

              <div
                v-if="$vuetify.breakpoint.name === 'xs' && step.elapsed > 0"
                class="caption"
              >
                <span class="grey--text text--darken-1">
                  <span :inner-html.prop="step.elapsed | durationNoSemicolons"/>
                </span>
              </div>
            </td>

            <td
              v-if="$vuetify.breakpoint.name !== 'xs'"
              style="white-space: nowrap;"
            >
              <span v-if="step.elapsed > 0" class="grey--text text--darken-1">
                <span :inner-html.prop="step.elapsed | durationNoSemicolons"/>
              </span>
            </td>

            <td>
              <v-btn icon color="primary" @click="editStep(id)">
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
              <v-btn
                v-show="false"
                :disabled="false"
                color="red"
                icon
                @click="deleteStep(action.id)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!--  <v-btn text color="primary" v-if="!closed" @click="createStep">  -->
      <!--  <v-icon>mdi-pencil-plus</v-icon> {{ $t('Add step') }}  -->
    <!--  </v-btn>  -->
  </div>
</template>

<script>
export default {
  name: 'RoundTimetable',

  props: {
    round: {
      type: Object,
      required: true
    },

    steps: {
      type: Array,
      default: () => [],
    },

    createStep: {
      type: Function,
      required: false,
      default: () => {}
    },

    editStep: {
      type: Function,
      required: false,
      default: () => {}
    },

    deleteStep: {
      type: Function,
      required: false,
      default: () => {}
    },

    closed: {
      type: Boolean,
      required: false,
      default: false
    },

    currentStep: {
      type: Object,
      required: true,
      default: null
    },
  },

  computed: {
    currentState () {
      if (!this.currentStep) {
        return 'ended'
      }

      return this.currentStep.state
    }
  },

  methods: {
    title (state) {
      const step = this.steps.find(s => s.state === state)
      return step ? step.title : state
    },

    muted (state) {
      const step = this.steps.find(s => s.state === state)
      return step ? step.muted : false
    },

    duration (current) {
      if (!current.results) {
        return null
      }

      const duration = current.results.find(
        (r) => r.type === `${current.state}.duration`
      )
      return duration ? duration.value : null
    },

    comment (current) {
      const step = this.steps.find(s => s.state === current.state)
      if (!step || !step.evaluation || !current.results) {
        return null
      }

      const evaluation = step.evaluation.inputs.find(
        i => i.type ===  'comment'
      )

      if (!evaluation) {
        return null
      }

      const comment = current.results.find(
        (r) => r.type === evaluation.scope
      )
      return comment && comment.comment.length > 0 ? comment.comment : null
    },

    evaluated (current) {
      const step = this.steps.find(s => s.state === current.state)
      if (!step || !step.evaluation) {
        if (
          (!current.results || current.results.length === 0) &&
          ![this.currentState, 'restart'].includes(current.state)
        ) {
          return 'valid'
        }

        return
      }

      if (!current.results) {
        return
      }

      const { inputs } = step.evaluation
      const eval_no_comment = inputs.filter(
        (i) => !['comment'].includes(i.type)
      )
      if (eval_no_comment.length === 0) {
        return null
      }

      const comment_scopes = inputs.filter(
        (i) => ['comment'].includes(i.type)
      ).map(i => i.scope)

      const current_no_comment = current.results.filter(
        (r) => !comment_scopes.includes(r.type)
      )

      return (current_no_comment.length >= eval_no_comment.length)
            ? 'valid' : 'invalid'
    }
  }
}
</script>
