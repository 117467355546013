<template>
    <div>
        <div
            ref="upper"
            :class="['msg font-weight-bold', className]"
        >
            {{ $t(msgForDevices) }}
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'RoundHeader',

    props: {
        upperMsg: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: 'text-center ',
        },
    },

    computed: {
        msgForDevices() {
            return (
                this.upperMsg.substr(0, 1).toUpperCase() +
                this.upperMsg.substr(1)
            );
        },
    },
};
</script>

<style lang="scss">
.msg {
    &.fresh {
        opacity: 1;
    }
    &.old {
        opacity: 0.5;
        transition: opacity 2s;
        transition-delay: 2s;
    }
}
</style>
