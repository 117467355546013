<template>
  <layout-bottom-btn>
    <template v-slot:content>
      <v-container class="pt-0 mt-0 div6" style="display: flex; flex-direction: column;" fluid>
        <v-row class="stepper mt-1" style="flex-grow: 0;display: block;">
          <v-col>
            <slot name="stepper" />
          </v-col>
        </v-row>

        <v-row class="py-0" style="flex-grow: 0;display: block;">
          <v-col class="py-0">
            <div id="roundHeader">
              <slot name="round-header" />
            </div>
          </v-col>
        </v-row>

        <v-row class="content div33" style="flex-grow: 1;display: block">

            <vue-scroll ref="vs" @handle-resize="handleResize">
            <div class="timetable-wrapper" style="width: 100%;">
                <slot name="round-timetable" />
            </div>
            </vue-scroll>

        </v-row>
      </v-container>
    </template>

    <template v-slot:bottom>
      <slot name="round-bottom" />
    </template>
  </layout-bottom-btn>
</template>



<script>
import vueScroll from 'vuescroll'
import LayoutBottomBtn from '@/components/layout-bottom-btn'
export default {
  name: 'RoundShowVertical',
  components: {
    LayoutBottomBtn,
    vueScroll,
  },

  mounted () {
    this.$refs.vs.scrollTo({ y: '100%' })
  },

  methods: {
    handleResize () {
      this.$refs.vs.scrollTo({ y: '100%' })
    }
  }
}
</script>



<style scoped lang="scss">
  .div6 {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display: flex;
    flex-direction: column;
  }
  .div33 {
    flex-grow: 1;
    overflow: auto;
    position: relative;
      &:after {
        content: "";
        height: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 75%);
      }
      &:before {
        content: "";
        height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 75%);
        pointer-events: none;
      }
  }
  .timetable-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
</style>
