<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col class="py-0">
        <div
          :class="[
            className,
            warningClass,
            'font-weight-regular',
            'ma-0 pa-0',
            'text--lighten-1',
            'text-center'
          ]"
        >
          <span v-if="elapsed" :inner-html.prop="elapsed | durationSemicolons" />
          <span v-else>00:00</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TotalTime',

  props: {
    elapsed: {
      type: Number,
      required: true
    },

    warning: {
      type: Number,
      required: false
    },

    paused: {
      type: Boolean,
      default: false
    },

    className: {
      type: String,
      default: '',
    }
  },

  computed: {
    warningClass() {
      if (!this.warning || this.elapsed < this.warning) {
        return 'grey--text';
      }

      return 'red--text';
    }
  }
}
</script>
