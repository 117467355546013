var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[(_vm.round.steps)?_c('v-simple-table',{staticClass:"transparent mb-2 pb-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.round.steps),function(step,id){return _c('tr',{key:("tr-" + id + "-" + (_vm.evaluated(step)) + "-" + (_vm.comment(step))),class:[ _vm.muted(step.state) ? 'grey--text' : '']},[_c('td',[_c('span',{class:[
                'md',
                'lg',
                'xl',
                'xxl'
              ].includes(_vm.$vuetify.breakpoint.name) ? 'body-1' : ''},[_vm._v(_vm._s(_vm.$date(new Date(step.timestamp)).format('H:mm')))])]),_c('td',{staticStyle:{"width":"100%"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.title(step.state)))+" "),(_vm.evaluated(step) === 'valid')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),(_vm.comment(step))?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-comment")]):_vm._e(),_c('span',{staticClass:"grey--text text--darken-1",attrs:{"v-if":_vm.duration(step)},domProps:{"innerHTML":_vm._f("durationNoSemicolons")(_vm.duration(step))}})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.comment(step)))])]),(_vm.$vuetify.breakpoint.name === 'xs' && step.elapsed > 0)?_c('div',{staticClass:"caption"},[_c('span',{staticClass:"grey--text text--darken-1"},[_c('span',{domProps:{"innerHTML":_vm._f("durationNoSemicolons")(step.elapsed)}})])]):_vm._e()],1),(_vm.$vuetify.breakpoint.name !== 'xs')?_c('td',{staticStyle:{"white-space":"nowrap"}},[(step.elapsed > 0)?_c('span',{staticClass:"grey--text text--darken-1"},[_c('span',{domProps:{"innerHTML":_vm._f("durationNoSemicolons")(step.elapsed)}})]):_vm._e()]):_vm._e(),_c('td',[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.editStep(id)}}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"disabled":false,"color":"red","icon":""},on:{"click":function($event){return _vm.deleteStep(_vm.action.id)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)])}),0)]},proxy:true}],null,false,2486127935)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }