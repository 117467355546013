var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-2"},[_c('v-row',_vm._l((_vm.additional),function(button,index){return _c('v-col',{key:("additional_" + index),staticClass:"pa-1",attrs:{"cols":_vm.maxWidth(button, index),"order":index + 1}},[_c('v-btn',{class:("" + (button.text)),attrs:{"width":"100%","minWidth":"100%","elevation":"0","color":_vm.color(button),"outlined":_vm.outline(button),"disabled":button.disabled !== undefined &&
                    button.disabled === true,"large":_vm.$vuetify.breakpoint.name !== 'xs'},on:{"click":function($event){return _vm.onAction(button)}}},[(button.icon)?_c('v-icon',{class:button.text},[_vm._v(" "+_vm._s(button.icon)+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.name !== 'xs' || !button.icon)?[_c('span',{domProps:{"innerHTML":_vm.$t(_vm.title(button))}})]:_vm._e(),(
                        button.showElapsed &&
                        _vm.$vuetify.breakpoint.name !== 'xs'
                    )?_c('time-elapsed',{attrs:{"elapsed":button.elapsed,"prefix":"(","suffix":")"}}):_vm._e()],2)],1)}),1),_c('v-row',_vm._l((_vm.buttons),function(button,index){return _c('v-col',{key:("button_" + index),staticClass:"pa-1",attrs:{"cols":button.width,"order":index + 1}},[_c('v-btn',{class:("" + (button.text)),attrs:{"width":"100%","minWidth":"100%","elevation":"0","color":_vm.color(button),"outlined":_vm.outline(button),"disabled":button.disabled !== undefined &&
                    button.disabled === true,"large":_vm.$vuetify.breakpoint.name !== 'xs'},on:{"click":function($event){return _vm.onAction(button)}}},[(button.icon)?_c('v-icon',{class:button.text},[_vm._v(" "+_vm._s(button.icon)+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.name !== 'xs' || !button.icon)?[_vm._v(" "+_vm._s(_vm.$t(_vm.title(button)))+" ")]:_vm._e(),(
                        button.showElapsed &&
                        _vm.$vuetify.breakpoint.name !== 'xs'
                    )?_c('time-elapsed',{attrs:{"elapsed":button.elapsed,"prefix":"(","suffix":")"}}):_vm._e()],2)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }