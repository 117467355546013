<template>
    <v-container class="ma-2">
        <v-row>
            <v-col
                v-for="(button, index) in additional"
                :key="`additional_${index}`"
                :cols="maxWidth(button, index)"
                :order="index + 1"
                class="pa-1"
            >
                <v-btn
                    :class="`${button.text}`"
                    width="100%"
                    minWidth="100%"
                    elevation="0"
                    :color="color(button)"
                    :outlined="outline(button)"
                    :disabled="
                        button.disabled !== undefined &&
                        button.disabled === true
                    "
                    :large="$vuetify.breakpoint.name !== 'xs'"
                    @click="onAction(button)"
                >
                    <v-icon v-if="button.icon" :class="button.text">
                        {{ button.icon }}
                    </v-icon>

                    <template v-if="$vuetify.breakpoint.name !== 'xs' || !button.icon">
                        <span :inner-html.prop="$t(title(button))" />
                    </template>

                    <time-elapsed
                        v-if="
                            button.showElapsed &&
                            $vuetify.breakpoint.name !== 'xs'
                        "
                        :elapsed="button.elapsed"
                        prefix="("
                        suffix=")"
                    />
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                v-for="(button, index) in buttons"
                :key="`button_${index}`"
                :cols="button.width"
                :order="index + 1"
                class="pa-1"
            >
                <v-btn
                    :class="`${button.text}`"
                    width="100%"
                    minWidth="100%"
                    elevation="0"
                    :color="color(button)"
                    :outlined="outline(button)"
                    :disabled="
                        button.disabled !== undefined &&
                        button.disabled === true
                    "
                    :large="$vuetify.breakpoint.name !== 'xs'"
                    @click="onAction(button)"
                >
                    <v-icon v-if="button.icon" :class="button.text">
                        {{ button.icon }}
                    </v-icon>

                    <template
                        v-if="$vuetify.breakpoint.name !== 'xs' || !button.icon"
                    >
                        {{ $t(title(button)) }}
                    </template>

                    <time-elapsed
                        v-if="
                            button.showElapsed &&
                            $vuetify.breakpoint.name !== 'xs'
                        "
                        :elapsed="button.elapsed"
                        prefix="("
                        suffix=")"
                    />
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TimeElapsed from './time-elapsed';

const _ = require('lodash');

export default {
    name: 'RoundActionBtns',

    components: {
        TimeElapsed,
    },

    props: {
        buttons: {
            type: Array,
            required: true,
        },

        additional: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        allButtons() {
            return  [
                ...this.additional,
                ...this.buttons
            ]
        },
    },

    methods: {
        outline(button) {
            if (typeof(button.outline) === 'boolean') {
                return button.outline
            }
            if (typeof button.outline !== 'function') {
                return button.state
            }

            return button.outline()
        },

        color(button) {
            if (typeof(button.color) === 'string') {
                return button.color
            }
            if (typeof button.color !== 'function') {
                return 'grey'
            }

            return button.color()
        },

        title(button) {
            if (typeof(button.message) === 'string') {
                return button.message
            }
            if (typeof button.message !== 'function') {
                return button.state
            }

            return button.message()
        },

        onAction(button) {
            if (!button.callback || typeof button.callback !== 'function') {
                return;
            }
            button.callback(button);
        },

        maxWidth(button, index) {
            if (this.additional.length % 2 === 1 && index === this.additional.length - 1) {
                return 12;
            }
            return 6;
        },
    },
};
</script>
